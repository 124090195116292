import "./Pay.scss";
import QRCode from "react-qr-code";

type PayParams = {
  uri: string;
  depositAddress: string;
  amount: string;
  cryptoAmount: string;
};

const Pay = (params: PayParams) => {
  const getQRCodeValue = () => {
    let value = `${params.depositAddress}?amount=${params.cryptoAmount}`;
    if (params.uri && params.uri !== "bitcoincash") value = `${params.uri}:${value}`;
    return value;
  };

  return (
    <>
      <p className="lead">Please pay now...</p>
      <div className="qrcode-wrapper px-4">
        <QRCode value={getQRCodeValue()} />
      </div>
    </>
  );
};

export default Pay;
