type CryptoAmountProps = {
  value: string;
  ticker: string;
};

const CryptoAmount = (params: CryptoAmountProps) => {
  return (
    <div className="crypto-amount">
      {params.value} {params.ticker}
    </div>
  );
};

export default CryptoAmount;
