import axios from "axios";
import Order from "../models/Order";

class ZapierService {
    private static readonly ZAPIER_WEBHOOK_URL: string = process.env.REACT_APP_ZAPIER_WEBHOOK_URL as string;

    static postOrder = async (email: string, order: Order): Promise<Order> => {
        return axios.create({
            transformRequest: [(data, _) => {
                return JSON.stringify(data);
            }]
        }).post(this.ZAPIER_WEBHOOK_URL, {
            email,
            order
        }).then((res) => {
            return res.data;
        });
    }
}

export default ZapierService;