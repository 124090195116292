import "./SelectCoin.scss";
import Coin from "../../models/Coin";
import { useEffect, useState } from "react";
import SideShiftService from "../../services/sideshift.service";

type SelectCoinProps = {
  value?: Coin;
  onChange: (coin: Coin) => void;
};

const SelectCoin = (props: SelectCoinProps) => {
  const [coins, setCoins] = useState<Coin[]>([]);

  useEffect(() => {
    SideShiftService.getDepositCoins().then(coins => {
      setCoins(coins);
      if (!props.value) props.onChange(coins[0]);
    })
  }, []);

  return (
    <select
      className="form-control mb-3"
      name="coin"
      value={props.value?.id}
      onChange={(event) => {
        props.onChange(
          coins.find((c) => c.id === event.target.value) ?? coins[0]
        );
      }}
    >
      {coins.map((coin, index) => {
        return (
          <option value={coin.id} key={index}>
            {coin.name}
          </option>
        );
      })}
    </select>
  );
};

export default SelectCoin;
