import "./Account.scss";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import TAccount from "../../models/Account";
import Pin from "../common/Pin";
import CookieService from "../../services/cookie.service";
import AccountInfo from "../common/AccountInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleLeft } from "@fortawesome/free-regular-svg-icons";
import Utils from "../utils/Utils";

enum AccountStep {
  ACCOUNT_INFO,
  PIN,
  CONFIRM_PIN,
}

const Account = () => {
  const [currentStep, setCurrentStep] = useState<AccountStep>(
    AccountStep.ACCOUNT_INFO
  );
  const [account, setAccount] = useState<TAccount>({
    notificationEmail: "",
    settlementAddress: "",
    settlementCoin: "",
    requireCustomerEmail: true,
    enableSecurityPin: true,
  });
  const [securityPin, setSecurityPin] = useState<string>("____");
  const [confirmPin, setConfirmPin] = useState<string>("____");
  const [isInvalidPin, setIsInvalidPIN] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleConfirmClick = () => {
    if (currentStep === AccountStep.ACCOUNT_INFO && account.enableSecurityPin) {
      setCurrentStep(AccountStep.PIN);
    } else if (currentStep === AccountStep.PIN) {
      setCurrentStep(AccountStep.CONFIRM_PIN);
    } else if (currentStep === AccountStep.CONFIRM_PIN && securityPin !== confirmPin) {
      setIsInvalidPIN(true);
      setTimeout(() => {
        setIsInvalidPIN(false);
      }, 1000)
      setConfirmPin("____");
    }
    else {
      let cookieObj = {};
      if (account.enableSecurityPin) {
        cookieObj = {
          ...account,
          securityPin,
        };
      } else {
        cookieObj = {
          ...account,
        };
      }
      for (let [key, value] of Object.entries(cookieObj)) {
        CookieService.setCookie(key, value as string);
      }
      navigate("/");
    }
  };

  const handleBackClick = () => {
    if (currentStep === AccountStep.PIN) {
      setCurrentStep(AccountStep.ACCOUNT_INFO);
    } else if (currentStep === AccountStep.CONFIRM_PIN) {
      setCurrentStep(AccountStep.PIN);
    }
  };

  return (
    <div className={`account ${isInvalidPin ? 'invalid' : ''}`}>
      {/* Header */}
      <div className="header">
        {currentStep !== AccountStep.ACCOUNT_INFO && (
          <button className="btn back-icon" onClick={handleBackClick}>
            <FontAwesomeIcon icon={faCircleLeft} />
          </button>
        )}
      </div>
      {/* Main */}
      <div className="main">
        {/* Account */}
        {currentStep === AccountStep.ACCOUNT_INFO && (
          <AccountInfo heading="Setup Your Account" value={account} onChange={setAccount} />)
        }
        {/* PIN */}
        {currentStep === AccountStep.PIN && (
          <Pin heading="Enter a PIN" value={securityPin} invalid={isInvalidPin} onChange={setSecurityPin} />
        )}
        {/* Confirm PIN */}
        {currentStep === AccountStep.CONFIRM_PIN && (
          <Pin heading="Confirm Your PIN" value={confirmPin} invalid={isInvalidPin} onChange={setConfirmPin} />)
        }
      </div>
      {/* Footer */}
      <div className="footer">
        {/* Confirm Button */}
        <button
          className="btn btn-primary btn-block"
          disabled={
            (currentStep === AccountStep.ACCOUNT_INFO &&
              (!account.notificationEmail || !Utils.validateEmail(account.notificationEmail) || !account.settlementAddress)) ||
            (currentStep === AccountStep.PIN && securityPin.includes("_")) ||
            (currentStep === AccountStep.CONFIRM_PIN && confirmPin.includes("_"))
          }
          onClick={handleConfirmClick}
        >
          Confirm
        </button>
      </div>
    </div>
  );
};

export default Account;
