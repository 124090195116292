import "./Settings.scss";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import TAccount from "../../models/Account";
import Pin from "../common/Pin";
import CookieService from "../../services/cookie.service";
import AccountInfo from "../common/AccountInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleLeft } from "@fortawesome/free-regular-svg-icons";
import Utils from "../utils/Utils";

enum SettingsStep {
  ACCOUNT_INFO,
  PIN,
  RESET_PIN,
  CONFIRM_PIN
}

const Settings = () => {
  const [account, setAccount] = useState<TAccount>({
    notificationEmail: CookieService.getCookie("notificationEmail"),
    settlementAddress: CookieService.getCookie("settlementAddress"),
    settlementCoin: CookieService.getCookie("settlementCoin"),
    requireCustomerEmail:
      CookieService.getCookie("requireCustomerEmail") === "true" ? true : false,
    enableSecurityPin:
      CookieService.getCookie("enableSecurityPin") === "true" ? true : false,
  });
  const [currentStep, setCurrentStep] = useState<SettingsStep>(
    account.enableSecurityPin ? SettingsStep.PIN : SettingsStep.ACCOUNT_INFO
  );
  const [securityPin, setSecurityPin] = useState<string>("____");
  const [confirmPin, setConfirmPin] = useState<string>("____");
  const [isInvalidPin, setIsInvalidPIN] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleNextClick = () => {
    if (currentStep === SettingsStep.PIN) {
      if (securityPin === CookieService.getCookie("securityPin")) {
        setSecurityPin("____");
        setCurrentStep(SettingsStep.ACCOUNT_INFO);
      } else {
        setIsInvalidPIN(true);
        setTimeout(() => {
          setIsInvalidPIN(false);
        }, 1000)
        setSecurityPin("____");
      }
    } else if (currentStep === SettingsStep.ACCOUNT_INFO) {
      let cookieObj: any = {
        ...account,
      };
      for (let [key, value] of Object.entries(cookieObj)) {
        CookieService.setCookie(key, value as string);
      }
      if (!account.enableSecurityPin) {
        CookieService.deleteCookie("securityPin");
      }
      navigate("/");
    }
    else if (currentStep === SettingsStep.RESET_PIN) {
      setCurrentStep(SettingsStep.CONFIRM_PIN)
    } else if (currentStep === SettingsStep.CONFIRM_PIN) {
      if (securityPin === confirmPin) {
        CookieService.setCookie("securityPin", securityPin);
        navigate("/");
      } else {
        setIsInvalidPIN(true);
        setTimeout(() => {
          setIsInvalidPIN(false);
        }, 1000)
      }
    }
  };

  const handleBackClick = () => {
    if (currentStep === SettingsStep.CONFIRM_PIN) {
      setCurrentStep(SettingsStep.RESET_PIN);
    }
    else if (currentStep === SettingsStep.RESET_PIN) {
      setCurrentStep(SettingsStep.ACCOUNT_INFO)
    }
    else {
      navigate("/");
    }
  };

  const handlResetPinClick = () => {
    setCurrentStep(SettingsStep.RESET_PIN);
  }

  const handleResetClick = () => {
    let cookieObj = {
      ...account,
      securityPin,
    };
    for (let key of Object.keys(cookieObj)) {
      CookieService.deleteCookie(key);
    }
    navigate("/account");
  };

  return (
    <div className={`settings ${isInvalidPin ? 'invalid' : ''}`}>
      {/* Header */}
      <div className="header">
        <button className="btn back-icon" onClick={handleBackClick}>
          <FontAwesomeIcon icon={faCircleLeft} />
        </button>
      </div>
      {/* Main */}
      <div className="main">
        {/* Account */}
        {currentStep === SettingsStep.ACCOUNT_INFO && (
          <AccountInfo heading="Settings" value={account} onChange={setAccount} />
        )}
        {/* PIN */}
        {currentStep === SettingsStep.PIN && (
          <Pin heading="Enter Your PIN" value={securityPin} invalid={isInvalidPin} onChange={setSecurityPin} />
        )}
        {/* Reset PIN */}
        {currentStep === SettingsStep.RESET_PIN && (
          <Pin heading="Enter a new PIN" value={securityPin} invalid={false} onChange={setSecurityPin} />
        )}
        {/* Confirm PIN */}
        {currentStep === SettingsStep.CONFIRM_PIN && (
          <Pin heading="Confirm PIN" value={confirmPin} invalid={isInvalidPin} onChange={setConfirmPin} />
        )}
      </div>
      {/* Action */}
      <div className="footer">
        {/* Reset PIN */}
        {(currentStep === SettingsStep.ACCOUNT_INFO) && (
          <button
            className="btn btn-link btn-block text-danger"
            onClick={handlResetPinClick}
          >
            Reset PIN
          </button>
        )}
        {/* Next/Confirm Button */}
        <button
          className="btn btn-primary btn-block"
          disabled={
            (currentStep === SettingsStep.ACCOUNT_INFO &&
              (!account.notificationEmail || !account.settlementAddress || !Utils.validateEmail(account.notificationEmail))) ||
            (currentStep === SettingsStep.PIN && securityPin.includes("_"))
          }
          onClick={handleNextClick}
        >
          {currentStep === SettingsStep.ACCOUNT_INFO ? "Save" : "Confirm"}
        </button>
        {/* Reset Account */}
        {(currentStep === SettingsStep.PIN) && (
          <button
            className="btn btn-link btn-block text-danger"
            onClick={handleResetClick}
          >
            Reset Account
          </button>
        )}
      </div>
    </div>
  );
};

export default Settings;
