import "./Amount.scss";

type AmountParams = {
  value: string;
};

const Amount = (params: AmountParams) => {
  return <div className="amount">&#163;{params.value}</div>;
};

export default Amount;
