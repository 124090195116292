import "./NumberPad.scss";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type NumberPadProps = {
  onKeyClick: (value: string) => void;
};

const NumberPad = (props: NumberPadProps) => {
  return (
    <table className="keypad">
      <tbody>
        <tr>
          <td onClick={() => props.onKeyClick("1")}>1</td>
          <td onClick={() => props.onKeyClick("2")}>2</td>
          <td onClick={() => props.onKeyClick("3")}>3</td>
        </tr>
        <tr>
          <td onClick={() => props.onKeyClick("4")}>4</td>
          <td onClick={() => props.onKeyClick("5")}>5</td>
          <td onClick={() => props.onKeyClick("6")}>6</td>
        </tr>
        <tr>
          <td onClick={() => props.onKeyClick("7")}>7</td>
          <td onClick={() => props.onKeyClick("8")}>8</td>
          <td onClick={() => props.onKeyClick("9")}>9</td>
        </tr>
        <tr>
          <td></td>
          <td onClick={() => props.onKeyClick("0")}>0</td>
          <td onClick={() => props.onKeyClick("<")}>
            <FontAwesomeIcon icon={faAngleLeft} />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default NumberPad;
