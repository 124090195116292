import NumberPad from "../common/NumberPad";

type TEnterAmountParams = {
  value: string | undefined;
  onChange: (value: string) => void;
};

const EnterAmount = (params: TEnterAmountParams) => {
  const onKeyClick = (key: string) => {
    if (!params.value) return;
    const groups = params.value.split(".");
    if (key !== "<") {
      const firstDecimalChar = groups[1].charAt(0);
      if (groups[0] === "0") groups[0] = firstDecimalChar;
      else groups[0] += firstDecimalChar;
      groups[1] = groups[1].slice(1);
      groups[1] += key;
      params.onChange(groups[0] + "." + groups[1]);
    } else {
      const tensPlaceChar = groups[0].charAt(groups[0].length - 1);
      groups[0] = groups[0].slice(0, groups[0].length - 1);
      if (!groups[0]) groups[0] = "0";
      groups[1] = tensPlaceChar + groups[1].slice(0, groups[1].length - 1);
      params.onChange(groups[0] + "." + groups[1]);
    }
  };

  return <NumberPad onKeyClick={onKeyClick} />;
};

export default EnterAmount;
