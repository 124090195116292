import "./Pin.scss";
import NumberPad from "../common/NumberPad";

type TPinProps = {
  heading: string;
  value: string;
  invalid: boolean;
  onChange: (value: string) => void;
};

const Pin = (props: TPinProps) => {
  const handleKeyClick = (value: string) => {
    let groups = props.value.split("");
    let index = groups.findIndex((g) => g === "_");
    if (value !== "<") {
      if (index === -1) return;
      groups.splice(index, 1, value);
    } else {
      if (index === -1) index = 4;
      groups.splice(index - 1, 1, "_");
    }
    let newPin = groups.join("");
    props.onChange(newPin);
  };

  return (
    <div className="pin-wrapper">
      <p className="lead text-center">{props.heading}</p>
      <div className={`pin ${props.invalid ? 'pin-invalid' : ''}`}>
        {props.value.split("").map((p, i) => {
          return (
            <span className="pin-char" key={i}>
              {p}
            </span>
          );
        })}
      </div>
      <NumberPad onKeyClick={handleKeyClick} />
    </div>
  );
};

export default Pin;
