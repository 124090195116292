import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type PaymentConfirmationProps = {
  orderId: string;
};

const PaymentConfirmation = (props: PaymentConfirmationProps) => {
  return (
    <div className="pay-confirmation">
      <FontAwesomeIcon icon={faCircleCheck} size="3x" />
      <h4 className="my-3">Payment Received!</h4>
      <p className="my-3">Order ID: {props.orderId}</p>
      <p className="lead my-3">You will receive an email receipt soon.</p>
    </div>
  );
};

export default PaymentConfirmation;
