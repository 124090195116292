import "./Home.scss";
import { useEffect, useState } from "react";
import SideShiftService from "../../services/sideshift.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { faCircleLeft } from "@fortawesome/free-regular-svg-icons";
import Amount from "./Amount";
import Pay from "./Pay";
import PaymentConfirmation from "./PaymentConfirmation";
import Order from "../../models/Order";
import ZapierService from "../../services/zapier.service";
import EnterAmount from "./EnterAmount";
import { useNavigate } from "react-router-dom";
import SelectCoin from "../common/SelectCoin";
import Coin from "../../models/Coin";
import CryptoAmount from "./CryptoAmount";
import CookieService from "../../services/cookie.service";
import Utils from "../utils/Utils";

enum HomeStep {
  COIN,
  EMAIL,
  AMOUNT,
  PAY,
  CONFIRMATION,
}

const Home = () => {
  const [step, setStep] = useState<HomeStep>(HomeStep.COIN);
  const [coin, setCoin] = useState<Coin>();
  const [email, setEmail] = useState<string>("");
  const [amount, setAmount] = useState<string>("0.00");
  const [minAmount, setMinAmount] = useState<string>("0.00");
  const [pair, setPair] = useState<any>();
  const [cryptoAmount, setCryptoAmount] = useState<string>("0.00");
  const [order, setOrder] = useState<Order>();

  const navigate = useNavigate();
  const isEmailRequired: boolean = CookieService.getCookie("requireCustomerEmail") === "true" ? true : false;

  let pollInterval: NodeJS.Timer;

  useEffect(() => {
    if (
      !CookieService.getCookie("settlementCoin") ||
      !CookieService.getCookie("settlementAddress")
    ) {
      navigate("/account");
    }
  }, []);

  const createOrder = () => {
    if (!coin) return;
    SideShiftService.createOrder(coin.id)
      .then((order) => {
        setOrder(order);
        setMinAmount(Math.ceil(order.depositMin / pair.rate * 1.31).toString() + ".00");
        setStep(HomeStep.AMOUNT);

      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCryptoAmount = (order: Order) => {
    if (!coin) return;
    pollOrderDeposists(order);
    setCryptoAmount(
      (pair.rate * Number.parseFloat(amount) * 1.31).toFixed(9)
    );
    setStep(HomeStep.PAY);
  };

  const pollOrderDeposists = (order: Order) => {
    let timerId = setInterval(() => {
      SideShiftService.getOrder(order.id)
        .then((order) => {
          if (order.deposits.length > 0) {
            if (pollInterval) clearInterval(pollInterval);
            setOrder(order);
            postOrderToZapier(order);
            setStep(HomeStep.CONFIRMATION);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }, 1000);
    pollInterval = timerId;
  };

  const postOrderToZapier = (order: Order) => {
    ZapierService.postOrder(email, order).catch((err) => {
      console.error(err);
    });
  };

  const getPair = () => {
    if (!coin) return;
    SideShiftService.getPair(coin.id).then((pair) => {
      setPair(pair);
      setStep(HomeStep.EMAIL);
    });
  }

  const handleNextClick = () => {
    if (step === HomeStep.COIN) {
      getPair();
    } else if (step === HomeStep.EMAIL) {
      createOrder();
    } else if (step === HomeStep.AMOUNT) {
      if (order) getCryptoAmount(order);
    } else {
      exit();
    }
  };

  const handleBackClick = () => {
    if (step === HomeStep.PAY) {
      if (pollInterval) clearInterval(pollInterval);
      setStep(HomeStep.AMOUNT);
    } else if (step === HomeStep.AMOUNT) {
      setStep(HomeStep.EMAIL);
    } else if (step === HomeStep.EMAIL) {
      setStep(HomeStep.COIN);
    }
  };

  const handleSettingsClick = () => {
    navigate("/settings");
  };

  const exit = () => {
    setEmail("");
    setAmount("0.00");
    setCryptoAmount("0.00");
    setOrder(undefined);
    setStep(HomeStep.COIN);
  };

  return (
    <div
      className={"home" + (step === HomeStep.CONFIRMATION ? " success" : "")}
    >
      {/* Header */}
      <div className="header">
        {step !== HomeStep.COIN && step !== HomeStep.CONFIRMATION && (
          <button className="btn back-icon" onClick={handleBackClick}>
            <FontAwesomeIcon icon={faCircleLeft} />
          </button>
        )}
        {step === HomeStep.COIN && (
          <button className="btn settings-icon" onClick={handleSettingsClick}>
            <FontAwesomeIcon icon={faGear} />
          </button>
        )}
      </div>
      {/* Main */}
      <div className="main">
        {/* Content */}
        <div className="content">
          {/* Coin */}
          {step === HomeStep.COIN && (
            <>
              <p className="lead">Choose your coin</p>
              <SelectCoin value={coin} onChange={setCoin} />
              <p className="font-weight-light px-3">
                This is the crypto currency you will use to pay with.
              </p>
            </>
          )}
          {/* Email */}
          {step === HomeStep.EMAIL && (
            <>
              <p className="lead">Enter your email</p>
              <input
                className="form-control mb-3"
                type="text"
                placeholder="Your email..."
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              ></input>
              <p className="font-weight-light px-3">
                We will use this to send receipt and communicate with you
              </p>
            </>
          )}
          {/* Amount */}
          {step === HomeStep.AMOUNT && (
            <>
              <div className="mb-5">
                <Amount value={amount} />
                <span className="text-muted">(&#163;{minAmount} min)</span>
              </div>
              <p className="lead">Enter the amount</p>
              <EnterAmount value={amount} onChange={setAmount} />
            </>
          )}
          {/* Pay */}
          {step === HomeStep.PAY && order && coin && coin.uri && (
            <>
              <Amount value={amount} />
              <div className="mb-5">
                <CryptoAmount value={cryptoAmount} ticker={coin.ticker} />
              </div>
              <Pay
                uri={coin.uri}
                depositAddress={order.depositAddress.address}
                amount={amount}
                cryptoAmount={cryptoAmount}
              />
            </>
          )}
          {/* Confirmation */}
          {step === HomeStep.CONFIRMATION && order && (
            <PaymentConfirmation orderId={order.id} />
          )}
        </div>
      </div>
      {/* Footer */}
      <div className="footer">
        {step !== HomeStep.PAY && (
          <button
            className={`btn btn-block ${step === HomeStep.CONFIRMATION ? "btn-secondary" : "btn-primary"
              }`}
            disabled={
              (step === HomeStep.AMOUNT && (amount === "0.00" || +amount < +minAmount)) ||
              (step === HomeStep.EMAIL && isEmailRequired && (!email || !Utils.validateEmail(email)))
            }
            onClick={handleNextClick}
          >
            {step === HomeStep.CONFIRMATION ? "Exit" : "Next"}{" "}
          </button>
        )}
      </div>
    </div>
  );
};

export default Home;
