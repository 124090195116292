import axios from "axios";
import Coin from "../models/Coin";
import Order from "../models/Order";
import CookieService from "./cookie.service";
import coinURIs from "../coin-uris.json";
import CoinURI from "../models/CoinURI";

const SettleInfo = {
  type: "variable",
  affiliateId: "0DD4T2e6v",
};

const CoinUris: CoinURI = coinURIs;

class SideShiftService {
  private static readonly SIDE_SHIFT_API_BASE_URL =
    process.env.REACT_APP_SIDE_SHIFT_API_BASE_URL;

  static createOrder = async (depositMethodId: string): Promise<Order> => {
    return axios
      .post(`${this.SIDE_SHIFT_API_BASE_URL}/orders`, {
        type: SettleInfo.type,
        depositMethodId: depositMethodId,
        settleMethodId: CookieService.getCookie("settlementCoin"),
        settleAddress: CookieService.getCookie("settlementAddress"),
        affiliateId: SettleInfo.affiliateId,
      })
      .then((res) => {
        return res.data;
      });
  };

  static getOrder = async (orderId: string): Promise<Order> => {
    return axios
      .get(`${this.SIDE_SHIFT_API_BASE_URL}/orders/${orderId}`)
      .then((res) => {
        return res.data;
      });
  };

  static getDepositCoins = async (): Promise<Coin[]> => {
    return axios
      .get(`${this.SIDE_SHIFT_API_BASE_URL}/facts`)
      .then((res: any) => {
        const coins: Coin[] = [];
        for (const [key, value] of Object.entries(
          res.data.depositMethods
        ).sort()) {
          const coin: any = value;
          if (!coin.enabled || (CoinUris[key] && CoinUris[key].inactive))
            continue;
          coins.push({
            name: coin.displayName,
            id: key,
            ticker: coin.asset,
            uri: CoinUris[key] ? CoinUris[key].uri : "",
          });
        }
        return coins;
      });
  };

  static getSettlementCoins = async (): Promise<Coin[]> => {
    return axios
      .get(`${this.SIDE_SHIFT_API_BASE_URL}/facts`)
      .then((res: any) => {
        const coins: Coin[] = [];
        for (const [key, value] of Object.entries(
          res.data.settleMethods
        ).sort()) {
          const coin: any = value;
          if (!coin.enabled || (CoinUris[key] && CoinUris[key].inactive))
            continue;
          coins.push({
            name: coin.displayName,
            id: key,
            ticker: coin.asset,
            uri: CoinUris[key] ? CoinUris[key].uri : "",
          });
        }
        return coins;
      });
  };

  static getPair = async (depositMethodId: string) => {
    return axios
      .get(
        `${this.SIDE_SHIFT_API_BASE_URL}/pairs/${CookieService.getCookie(
          "settlementCoin"
        )}/${depositMethodId}`
      )
      .then((res) => {
        return res.data;
      });
  };
}

export default SideShiftService;
