import { useEffect, useState } from "react";
import TAccount from "../../models/Account";
import Coin from "../../models/Coin";
import SideShiftService from "../../services/sideshift.service";

type TAccountInfoProps = {
  heading: string;
  value: TAccount;
  onChange: (value: TAccount) => void;
};

const AccountInfo = (props: TAccountInfoProps) => {
  const [coins, setCoins] = useState<Coin[]>([]);

  useEffect(() => {
    SideShiftService.getSettlementCoins().then(coins => {
      setCoins(coins);
      if (!props.value.settlementCoin) handleSettlementCoinChange(coins[0].id);
    });
  }, [])

  const handleNotificationEmailChange = (value: string) => {
    let tempAccount = { ...props.value };
    tempAccount.notificationEmail = value;
    props.onChange(tempAccount);
  };

  const handleSettlementAddressChange = (value: string) => {
    let tempAccount = { ...props.value };
    tempAccount.settlementAddress = value;
    props.onChange(tempAccount);
  };

  const handleSettlementCoinChange = (value: string) => {
    let tempAccount = { ...props.value };
    tempAccount.settlementCoin = value;
    props.onChange(tempAccount);
  };

  const handleRequireCustomerEmailChange = (checked: boolean) => {
    let tempAccount = { ...props.value };
    tempAccount.requireCustomerEmail = checked;
    props.onChange(tempAccount);
  };

  const handleEnableSecurityPinChange = (checked: boolean) => {
    let tempAccount = { ...props.value };
    tempAccount.enableSecurityPin = checked;
    props.onChange(tempAccount);
  };

  return (
    <div className="account-info">
      <p className="lead text-center mb-5">{props.heading}</p>
      {/* Notification Email */}
      <div className="form-group">
        <label htmlFor="notificationEmail">Notification Email</label>
        <input
          className="form-control"
          name="notificationEmail"
          placeholder="Email address..."
          value={props.value.notificationEmail}
          onChange={(e) => {
            handleNotificationEmailChange(e.target.value);
          }}
        />
      </div>
      {/* Settlement Address */}
      <div className="form-group">
        <label htmlFor="settlementAddress">Settlement Address</label>
        <input
          className="form-control"
          name="settlementAddress"
          placeholder="Settlement address..."
          value={props.value.settlementAddress}
          onChange={(e) => {
            handleSettlementAddressChange(e.target.value);
          }}
        />
      </div>
      {/* Settlement Coin */}
      <div className="form-group">
        <label htmlFor="coin">Settlement Coin</label>
        <select
          className="form-control mb-3"
          name="coin"
          value={props.value.settlementCoin}
          onChange={(event) => {
            handleSettlementCoinChange(event.target.value);
          }}
        >
          {coins.map((coin) => {
            return (
              <option value={coin.id} key={coin.id}>
                {coin.name}
              </option>
            );
          })}
        </select>
      </div>
      {/* Require Customer Email */}
      <div className="custom-control custom-switch my-4">
        <input
          type="checkbox"
          className="custom-control-input"
          id="requireCustomerEmail"
          checked={props.value.requireCustomerEmail}
          onChange={(e) => {
            handleRequireCustomerEmailChange(e.target.checked);
          }}
        />
        <label className="custom-control-label" htmlFor="requireCustomerEmail">
          Require Customer Email
        </label>
      </div>
      {/* Enable Security PIN */}
      <div className="custom-control custom-switch my-4">
        <input
          type="checkbox"
          className="custom-control-input"
          id="enableSecurityPin"
          checked={props.value.enableSecurityPin}
          onChange={(e) => {
            handleEnableSecurityPinChange(e.target.checked);
          }}
        />
        <label className="custom-control-label" htmlFor="enableSecurityPin">
          Enable Security PIN
        </label>
      </div>
    </div>
  );
};

export default AccountInfo;
